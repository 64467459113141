/**
 * File: styles.css
 * Description : Contains all necessary styling for the Repair Geek - Laptop And Computer Fixing Service Center HTML5 Template
 * Author: xenioushk
 * URL: https://themeforest.net/user/xenioushk
 * Project: Repair Geek - Laptop And Computer Fixing Service Center HTML5 Template
 * Version: 1.0.3
 * Last Update: 26-07-2018
 **/

/*========================================================================*/
/*   TABLE OF CONTENT
/*========================================================================*/
/*
/*      01. GENERAL STYLING 
/*      02. HEADER
/*      03. MAIN NAVIGATION MENU
/*      04. HOME PAGE
/*      05. BLOG LAYOUT
/*      06. BLOG SINGLE PAGE
/*      07. EVENTS/CAMPAIGN PAGE
/*      08. ABOUT US PAGE
/*      09. GALLERY PAGE
/*      10. SERVICE PAGE
/*      11. 404 PAGE
/*      12. FOOTER
/*      13. SIDEBAR & WIDGETS
/*      14. RESPONSIVE STYLING
/*
/*========================================================================*/

@import 'variables.less';
@import 'mixins.less';
@import 'reset.less';

/***********************************************************************************************/
/* 01. GENERAL STYLING  */
/***********************************************************************************************/

body {

    font: 16px/30px @body-font;
    color: lighten(@default-font-color, 20%);
    font-style: normal;
    font-weight: 400;
    position: relative;
    text-rendering: optimizeLegibility;
}

img{
    max-width: 100%;
}

a {
    color: @heading-font-color;
    text-decoration: none;   
    .transition(all, 0.3s);
}

a:hover,
a:focus {

    color: darken(@primary-color, 20%);
    text-decoration: none;

}

blockquote{
    font: @body-font;
    color: @text-color;
}

h1, h2, h3, h4, h5, h6 {
    font-family: @heading-font;
    font-style: normal;
    font-weight: 400;
    color: @heading-font-color;
    line-height: 32px;
    margin: 0 0 16px 0;
}

h1{
    font-size: 36px;
}

h2{
    font-size: 24px;
}

h2+p{
    margin-top: 12px;
}

h3{
    font-size: 20px;
}

h3+p{
    margin-top: 12px;
}

h4{
    font-size: 18px;
}

h4+p{
    margin-top: 12px;
}

h5{
    font-size: 15px;
}

h6{
    font-size: 15px;
}

* + p {
    margin-top: 12px;
}

p {
    font-style: normal;
    font-weight: 400;
    margin: 12px 0 12px;
    padding: 0;
}

ul, li{

    list-style-type: none;
}

input[type=text],
input[type=password],
input[type=submit],
input[type=button],
textarea{

    .round(0);
    .drop-shadow(0, 0, 0, 0, 0);

    &:focus{

        .drop-shadow(0, 0, 0, 0, 0);

    }

}

strong{
    font-weight: 600;
}

/*----- CUSTOM MARGIN ----*/

.no-margin{
    margin: 0px !important;
}

.margin-top-5{
    margin-top: 5px;
}

.margin-top-12{
    margin-top: 12px !important;
}

.margin-top-24{
    margin-top: 24px !important;
}

.margin-top-32{
    margin-top: 32px !important;
}

.margin-bottom-5{
    margin-bottom: 5px;
}

.margin-bottom-12{
    margin-bottom: 12px !important;
}

.margin-bottom-24{
    margin-bottom: 24px !important;
}

.margin-bottom-32{
    margin-bottom: 32px !important;
}

.margin-bottom-80{
    margin-bottom: 80px;
}

.margin-right-0{
    margin-right: 0px;
}

/*----- CUSTOM PADDING ----*/

.no-gutter{
     [class^="col-"] {
        padding: 0px;
    }
}

.no-padding{
    padding: 0px !important;
}

.padding-left-0{
    padding-left: 0px;
}

.padding-right-0{
    padding-right: 0px;
}

.padding-right-5{
    padding-right: 5px;
}

.padding-right-11{
    padding-right: 11px;
}

/*----- PRE LOADER ----*/

#preloader{
    background: rgba(255,255,255,0.95);
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 9999;

    span{
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        position: absolute;
        top: 50%;
    }
}

// BACK TO TOP

#backTop{
    width:16px;
    height:16px;
    padding:10px;
    border-radius:0px;
    text-indent:-9999px;
    cursor:pointer;
    z-index:999999999;
    display:none;
    box-sizing:content-box;
    -webkit-box-sizing:content-box;
    opacity: 1;
    .transition(opacity, 0.2s);
    &:hover{
        opacity: 1;
    }
}

#backTop:after{
    position: absolute;
    content: '-';
    width: 16px;
    height: 16px;
    text-align: center;
    top: 0;
    left: 0;
    color: #FFF;
}

#backTop.custom{

    background-color: @primary-color;
    background-image: url('../images/uparr-48-b.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border: 0px solid lighten(@primary-color, 20%);
    .transition( 0.2s );

    &:hover{
        background-color:  rgba(255,255,255,1);
        border: 0px solid darken(@primary-color, 20%);
    }
}

// COMMON UTILITY CLASSES

.text-primary-color{
    color: @primary-color;
}

.text-light-color{
    color: @light-text-color;
}

.text-dark-color{
    color: @dark-text-color;
}

.text-highlighter-white{
    font-weight: 500;
}

.text-bold{
    font-weight: bold;
}

.text-highlighter{
    color: @primary-color;
}

.text-highlighter-white{
    color: @light-text-color;
}

.alert,
.progress,
.pre {
    .round(0px);
}

hr{
    margin: 0px;
    border: 0px;
    border: 0px;
    height: 1px;
    background: lighten( @light-border-color,15%);
}

.no-border{
    border: 0px !important;
}

.inline {
    li {
        display: inline-block;
        margin-right: 24px;

        &:last-child {
            margin-right: 0;
        }
    }
}


.narrow-p {
    margin: 0 auto 1.5em;

    width: 30%;
}

.fr { float: right;}
.fl { float: left;}
.cb { clear: both;}
.db{display: block;}

.overflow-hidden{
    overflow: hidden;
}

span.border-light-dotted{
    border-bottom: 1px dotted @primary-color;
}

span.border-bold-dashed{
    border-bottom: 2px dashed @primary-color;
}

/*-----  BLOCKQUOTE ----*/

blockquote {

    padding: 12px 26px 26px 42px;
    margin: 0 0 20px;
    font-size: 14px;
    font-family: @body-font;
    background: @pure-white-bg;
    position: relative;
    border: 1px solid @light-border-color;
    border-left: 4px solid darken(@light-border-color, 50%);
    .transition(0.3s);

    &:before {
        display: block;
        position: absolute;
        left: 12px;
        top: 14px;
        content: "\f10d";
        font-family: 'FontAwesome';
        font-size: 15px;
        color: #d6d6d6;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    p {

        font-size: 14px;
        line-height: 24px;


    }

    &:hover{

        border: 1px solid darken(@light-border-color,15%);
        border-left: 4px solid darken(@light-border-color, 50%);

    }

}

blockquote.testimonial {
    background: @pure-white-bg;
    border-top: 1px solid @light-border-color;
    border-right: 1px solid @light-border-color;
    border-bottom: 1px solid @light-border-color;
    margin-bottom: 0;
}

cite {
    text-align: right;
    display: block;
}

cite:before { 
    margin-right: 4px;
}

.bq-author {
    padding: 16px 0 0 110px;
    position: relative;
}

.bq-author:before {
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    left: 69px;
    width: 0;
    height: 0;
    border-left: 22px solid transparent;
    border-right: 0 solid transparent;
    border-top: 22px solid #dcdcdc;
    z-index: 2;
}

.bq-author:after {
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    left: 70px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 0 solid transparent;
    border-top: 20px solid #fff;
    z-index: 3;
}

.bq-author h6 {
    margin-bottom: 6px;
}

.bq-author .bq-author-info {
    font-size: 12px;
}


/*----- ORDERED/UNORDERED LISTS ----*/

ol {

    li {
        padding: 0;
        margin: 0 0 10px 25px;
        list-style-type: decimal;
    }
}

/*----- ACCORDION ----*/

.accordion .panel{
    margin-bottom: 3px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
}

.accordion .panel .panel-title a{
    display: block;
}

span.label {
    margin-right: 1px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    font-size: 100%;
}

/*----- BUTTONS ----*/

#btn,
.btn {
    text-align: center;
    .transition(0.3s);

    &:hover{

        color: #000000;

    }

}

.btn-custom{
    text-transform: uppercase;
    text-align: center;
    .transition(0.3s);
    border: 1px solid @light-border-color;
    background: @primary-color;
    color: @light-text-color;
    .round(0px);
    padding: 12px 14px;

    &:hover{
        border-color: darken(@light-border-color,15%);
        color: @primary-color;
        background: @pure-white-bg;
    }

}

.btn-service{
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    .transition(0.3s);
    border: 0px solid @light-border-color;
    background: @primary-color;
    color: @light-text-color;
    .round(24px);
    margin: 12px 0;
    padding: 16px 24px;
    letter-spacing: 0.1em;
    font-weight: 600;

    &:hover{

        color: @light-text-color;
        background: darken(@primary-color,5%);
    }

}

.btn-custom-inverse{
    text-align: center;
    border: 1px solid @light-border-color;
    color: @primary-color;
    background: @pure-white-bg;
    padding: 12px 14px;
    .transition(0.3s);
    .round();
    &:hover{
        background: @primary-color;
        color: @light-text-color;
    }
}

a.btn-load-more{
    margin-bottom: -36px;
    margin-top: 48px;
    background: transparent;
    color: @primary-color;
    background: @pure-white-bg;
    border: 1px solid @primary-color;
    padding: 12px 24px;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    .round(0px);
    .transition(0.3s);
    &:hover{
        color: @light-text-color;
        background: @primary-color;
        border: 1px solid @primary-color;
    }
}

a.btn-about{
    font-size: 16px;
    margin-top: 16px;
    background: @primary-color;
    border:2px solid @primary-color;
    color: @light-text-color;
    height: 42px;
    line-height: 24px;
    padding-left: 12px;
    padding-right: 12px;
    letter-spacing: 0.03em;
    font-weight: 600;
    text-align: left;
    .round(0px);

    &:hover{
        background: darken(@primary-color, 10%);
        border:2px solid @primary-color;
        color: @light-text-color;
    }

}

.button{
    display: inline-block;
    .round();
    color:white;
    font-weight: bold;	
    height: 3em;
    line-height: 1em;
    padding: 1em;

}

.small-button{
    display: inline-block;
    .round(0px);
    color:@dark-color;
    font-weight: bold;	
    height: 0;
    line-height: 0;
    padding: 1em 1em;
    margin: 6px 0;
    width: 100%;

}

/*----- CTA VIDEO  ----*/

.cta-video-icon{
    border: 5px solid @dark-border-color;
    color: @primary-color;
    width: 120px;     
    height: 120px;
    font-size: 110px;
    line-height: 112px;
    text-align: center;
    margin: 0 auto 24px;
    display: inline-block;
    .round(100%);

    >i{
        margin-left:2px; 
    }

    &:hover{
        color: @dark-text-color;
        border: 5px solid @primary-color;

    }
}

.cta-video-icon-invert{
    color: @light-text-color;
    border: 5px solid @primary-color;

    &:hover{
        border: 5px solid @light-border-color;
        color: @primary-color;
    }

}

/*----- CTA BUTTONS  ----*/


.btn-cta{
    background: @dark-bg;
    color: @light-text-color;
    display: inline-block;
    font-size:16px;
    line-height: 36px;
    font-weight: 600;
    padding:12px 42px;
    position: relative;
    transition: all 0.3s ease-out 0s;
    .round(32px);

    &:hover {
        color: @light-text-color;
        background-color: @primary-color;
    }
    
}

.btn-square{
    .round(0px);
}

.btn-rounded{
    .round(4px);
}

.btn-transparent{
    background: rgba(0,0,0,0.2);
    color: @dark-bg;
}

.btn-bordered{
    border: 1px solid @dark-bg;
    
    &:hover{
        border: 1px solid @primary-color;
    }
}

.btn-invert{
    color: @light-text-color;
    background-color: @primary-color;
    
    &:hover{
        background: @light-bg;
        color: @dark-text-color;
    }
    
}


.btn-appointment-submit{
    background: @primary-color;
    border: 2px solid @primary-color;
    color: @light-text-color;
    display: inline-block;
    font-weight: 400;
    padding:12px 32px;
    position: relative;
    text-transform: uppercase;
    transition: all 0.3s ease-out 0s;
    .round(32px);

    &:focus,
    &:hover {
        background-color: darken(@primary-color, 10%);
        color: @light-text-color;
    }

}  

/*----- COMMENT BUTTON ----*/

#comment_btn{
    .round(0px);
    text-align: center;
    .transition(0.3s);
    border: 1px solid @light-border-color;
    .drop-shadow(0, 1px, 1px, 0, 0.1);
    background: transparent;
    color: @text-color;
    padding: 6px 12px;

    &:hover{
        border: 1px solid @primary-color;
        color: @primary-color;
        background: transparent;
    }

}

// ERROR NOTIFICATION

#contact-form .p-errors label{
    color: lightcoral;
}

#contact-form .p-errors input[type="text"],
#contact-form .p-errors input[type="email"],
#contact-form .p-errors textarea{
    border-color: lightcoral;
}

// WordPress Theme Support

.wp-caption {
    text-align: center;
}

.wp-caption .wp-caption-text {
    margin-bottom: 0px;
    font-size: 12px;
    font-style: italic;
    line-height: 34px;
}

.sticky{   
    margin-bottom: 21px;
    background: #FFFFFF;    
}

/*------------------------------ Gallery Caption  ---------------------------------*/

.gallery-caption{}

/*------------------------------ By Post Author ---------------------------------*/

.bypostauthor {}


/***********************************************************************************************/
/* 02. HEADER */
/***********************************************************************************************/

.main-header{

    // New
    height: auto;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 1000;

    .sticky-wrapper{
        background: rgba(255,255,255,1);
    }

    .navgiation-wrapper{
        background: rgba(255,255,255,1);
    }

    .top-bar {
        
        overflow: hidden;
        background: @top-bar-bg;
        line-height:  48px;
        color: @light-text-color;
        font-size: 16px;

        i{
            color: @light-text-color;
            margin-right: 5px;
            display: inline-block;
        }

        a{
            color: @light-text-color;
        }

    }

    ul.top-bar-info{
        list-style: none;
        padding: 0;
        margin: 0;
        
        .top-bar-dot{
            width: 6px;
            height: 6px;
            background: @primary-color;
            display: inline-block;
            margin-right: 12px;
            position: relative;
            top: -1px;
        }

        li{

            margin-right: 6px;
            display: inline;
            
            &:last-child{
                margin-right: 0px;
            }

        }

    }
            
    a.free-estimate{
        font-size: 14px;
        padding: 0;
        display: block;
        text-align: center;
        color: @light-text-color;
        font-weight: 600;
        background: @primary-color;
        border: 0px solid transparent;
        letter-spacing: 1px;

        &:hover{
            background: darken( @primary-color,5%);
            border: 0px solid transparent;
        }

    }

    .top-bar-social{
        text-align: center;
        color:@light-text-color;
        font-size: 16px;
        border: 0px solid #FFF;
        letter-spacing: 1px;
        text-transform: uppercase;
        .round(0px);

        a{
            display: inline-block;
            .transition(all, 0.3s);

            i.fa{
                width: 24px;
                height: 24px;
                text-align: center;
                color:@light-text-color;
                line-height: 24px;
                font-size: 14px;
                position: relative;
                top: -2px;
            }

            &:hover{

                i.fa{
                    color:@primary-color;
                }


            }

        }

    }

}

.sticky-header {
    height: auto;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 1000;

    .top-bar {

        background: @pure-black-bg;

    }

    .navgiation-wrapper{
        background: rgba(255,255,255,0.97);
    }


    .sticky-wrapper{
        background: transparent !important;
    }

}

// SITE LOGO

.logo {
    margin-top: 32px;
    margin-bottom: 0;
}

/***********************************************************************************************/
/* 03. MAIN NAVIGATION MENU */
/***********************************************************************************************/

a.logo {
    margin-top: 6px;
    display: inline-block;
    img{
        display: block;
    }
}

.navbar-default {
    
    border: none;
    background: transparent;
    margin-bottom: 0px;
    padding: 0 !important;
    
    .navbar-nav {
        margin-top: 10px;
    }
    
    .navbar-nav > li {
        margin-left: 12px;
    }
    
    .navbar-nav > li > a {
        color: #000;
        font-size: 14px;
        letter-spacing: 0.05em;
        font-weight: 600;
        text-transform: uppercase;
        padding-bottom: 22px;
        line-height: 32px;
        color: darken(@text-color, 10%);
    }
    
    .navbar-nav > li > a:hover {
        color: @primary-color;
    }
    
    .navbar-nav li.drop {
        position: relative;
        a{

            &:after{
                content: ""; /*-----  Change Here----*/
                font-family: fontawesome;
                position: absolute;
                right: -3px;
                top: 15px;
                font-weight: normal;
            }

        }
    }
    
    .navbar-nav li ul.drop-down {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 69px;
        left: 0;
        width: 190px;
        visibility: hidden;
        opacity: 0;
        z-index: 3;
        text-align: left;
        .transition(all, 0.3s);
    }
    
    .navbar-nav li ul.drop-down li {
        list-style: none;
        display: block;
        margin: 0;
    }
    
    .navbar-nav li ul.drop-down li ul.drop-down.level3 {
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform: rotateX(-90deg);
        -moz-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
        top: 0px;
        left: 100%;
        border-bottom: none;
        a{

            &:after{
                content: '';
            }

        }
    }

    .navbar-nav li ul.drop-down li a {
        font-family: @menu-font;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0.1em;
        display: inline-block;
        text-decoration: none;
        display: block;
        color: darken(@text-color, 10%);
        padding: 12px 20px;
        text-transform: uppercase;
        background: darken( @pure-white-bg, 3%);
        margin: 0;
        border: none;
        &:after{
            content: '';
            font-family: FontAwesome;
            position: absolute;
            right: 12px;
        }
        
        &:hover {
            background: @primary-color;
            color: @light-text-color;
        }
        
    }

    .navbar-nav li ul.drop-down li.drop a {
        display: inline-block;
        text-decoration: none;
        display: block;
        color: darken(@text-color, 10%);
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0.1em;
        padding: 12px 20px;
        text-transform: uppercase;
        margin: 0;
        border: none;

        &:after{
            content: ''; /*-----  Change Here----*/
            font-family: FontAwesome;
            position: absolute;
            right: 12px;
        }

        &:hover{
            color: @light-text-color;
        }

    }
    
    .navbar-nav li ul.drop-down li:hover ul.drop-down.level3 {
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        transform: rotateX(0deg);
        a{

            &:after{
                content: '';
            }

        }

    }
    
    .navbar-nav li ul.drop-down li:last-child {
        border-bottom: none;
    }
    
    .navbar-nav li:hover > ul.drop-down {
        visibility: visible;
        opacity: 1;
    }

}

header.one-page .navbar-nav > li {
    margin-left: 0;
}

header.one-page .navbar-nav > li > a span {
    color:@primary-color;
}

// STICKY TOP MENU

.stuck {
    position:fixed;
    top:0;    
    left: 0px;
    right: 0px;
    z-index: 99;
    opacity: 1;
    .transition(opacity, 0.3s);
    background: rgba(0,0,0,0.8);
    border-bottom: 1px solid lighten( @light-border-color, 12%);
    width: 100%;
    padding: 0px;
    margin: 0 !important;
    .drop-shadow( 0px,  0px, 5px, 10px, .03 );
}

// HOME 1 MENU

.menu-container{
    border: 5px solid rgba(0,0,0,0.5);
    background: rgba(0,0,0,0.8);
    margin-top: 20px;
    padding: 0px 20px;
    .navbar-nav li ul.drop-down {
        top: 66px;
    }
}

/***********************************************************************************************/
/* 04. HOME PAGE */
/***********************************************************************************************/

// SECTION CONTENT BLOCK.

.section-content-block{
    padding: 100px 0 100px 0;
    background: @pure-white-bg;
}

.section-pure-white-bg{
    background: @pure-white-bg !important;
}

.section-pure-black-bg{
    background: @pure-black-bg !important;
}

.section-secondary-bg{
    background: @second-bg-color !important;
} 

.section-heading-wrapper{
    
    margin-bottom: 32px;
    
    h4{
       font-size: 22px; 
       line-height: 24px;
       margin-bottom: 0px;
       color: @primary-color;
    }

    h2{
        font-size: 48px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 48px;
        position: relative;
    }
    
    span.heading-separator{
            display: inline-block;
            width: 2px;
            height: 16px;
            background: @primary-color;
            margin-top: 8px;
    }
    
}

.section-heading-wrapper-alt{
    
    margin-bottom: 32px;
    
    h4{
       font-size: 22px; 
       line-height: 24px;
       margin-bottom: 0px;
       color: @primary-color;
    }

    h2{
        font-size: 48px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 48px;
        position: relative;
        color: @light-text-color;
    }
    
    span.heading-separator{
            display: inline-block;
            width: 2px;
            height: 16px;
            background: @primary-color;
            margin-top: 8px;
    }
    
}

/***********************************************************************************************/
/*  HOME PAGE 01 */
/***********************************************************************************************/

// SLIDER 1

.slider-wrap{
    clear: both;
}

#slider_1{

    text-align: center;

    .item img {
        display: block;
        width: 100%;
        height: auto;
    }

    .slider_item_container{
        position: relative;
        text-align: center;
        width: 100%;
        min-height: 800px;
        background-repeat: no-repeat;
        background-size: cover;
        background: #000000;
    }

    .slider-content {
        position: absolute;
        top: 50%;
        transform: translateY(-55%);
        width: 100%;

        h2 {
            position: relative;
            font-size:  72px;
            line-height: 72px;
            font-weight: 700;
            color: @light-text-color;

            span{
                padding: 0 3px;
                font-weight:700;
                color: lighten(@primary-color,15%);
            }

        }

        h3 {
            font-family: @slider-font;
            color: @light-text-color;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
        }

        .slider-button{
            display:block;
            margin-top:30px;

            a.btn-slider {
                font-size: 16px;
                display: inline-block;
                background: @primary-color;
                border: 2px solid @primary-color;
                color: @light-text-color;
                line-height: 42px;
                padding: 6px 24px;
                text-transform:uppercase;
                font-weight: 600;
                text-align: center;
                letter-spacing: 0em;
                .round(32px);

                &:hover{
                    background: darken(@primary-color,10%)
                }

            }

        }


    }

    .owl-nav {
        
        .transition(all, 0.5s);
        opacity: 0;
        margin: 0;
        z-index: 1;
        
        [class*="owl-"] {
            color: #FFF;
            font-size: 14px;
            margin: 5px;
            padding: 4px 7px;
            background: #d6d6d6;
            display: inline-block;
            cursor: pointer;
            border-radius: 3px;
        }
        
        .owl-prev {
            left: 3%;
            position: absolute;
            padding: 0;
            top: 50%;
            width: 42px;
            font-size: 60px;
            color: @light-text-color;
            background: transparent;
            margin-top: -42px;
            .transition(all, 0.5s);
            text-align: center;
            
            &:hover{

                color: @primary-color;
                    
            }
        }

        .owl-next {
            right: 3%;
            position: absolute;
            padding: 0;
            top: 50%;
            width: 42px;
            font-size: 60px;
            color: @light-text-color;
            background: transparent;
            margin-top: -42px;
            .transition(all, 0.5s);
            text-align: center;
            
            &:hover{

                color: @primary-color;
                    
            }
        }
    }

    .owl-dots{
        margin: 0;
        position: absolute;
        bottom: 24px;
        left: 50%;
        margin-left: -50px;
        width: 100px;
        text-align: center;
        .owl-dot{
            .round(4px);
            display: inline-block;
            padding: 2px;
            width: 16px;
            height: 8px;
            background: @light-bg;
            margin: 0 4px;
            span{
                display: none;
            }
        }
        .active{
            .round(4px);
            width: 24px;
            background: @primary-color;
            span{
                display: none;
            }
        }
    }
    
    &:hover{
        
        .owl-nav {
          opacity: 1;  
          
          .owl-prev {
              left: 0%;
          }
          
          .owl-next {
              right: 0%;
          }
          
        }
        
    }


}


/*----- ABOUT US ----*/
  
.our-experience{
    color: @heading-font-color;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 24px;
    margin-top: 0;
    padding-right: 18px;

    span{
        display: inline;
        color: @primary-color;
    }
}

.about-us-intro{
    margin-top: 12px;
}

.about-us-img{
    
    position: relative;
    overflow: hidden;
    
    
    i.fa{
        position: absolute;
        top: 0;
        left: 0;
        width: 48px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-weight: 24px;
        display: inline-block;
        background: @pure-white-bg;
        color: @dark-text-color;
        top: 50%;
        left: 50%;
        margin-top: -24px;
        margin-left: -24px;
        
    }
    
}

.highlight-box{
    
    margin-top: 24px;
    border:1px solid @border-color;
    padding: 48px;
    background: darken(@pure-white-bg, 1%);

    .highlight-text{
        font-size:24px;
        font-weight:700;
        letter-spacing:0.03em;
        margin-bottom:0;
        color:@text-color;
    }

    
    &:hover{
        background: darken(@pure-white-bg,1.5%);
    }
    
}

/*-----  SERVICE LAYOUTS ----*/

.service-block-1{
    overflow: hidden;
    background: darken(@pure-white-bg,2%);
    position: relative;
    margin: 16px 0px;
    padding: 0 0 16px 0px;
    
    a{
        display: block;
    }
    
    
    figure{
        
        position: relative;
        
        img{
            opacity: 0.9;
        }

        span.fa{
            position: absolute;
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            display: inline-block;
            top: 50%;
            left: 50%;
            margin:  -30px 0 0 -30px;
            font-size: 24px;   
            .transition(all, 0.5s);
            color: @light-text-color;
            background: @primary-color;
           .round(100%);
           transform: scale(0);
        }
        
    }

    h3{
        font-size: 32px;
        line-height: 42px;
        letter-spacing: 0.0em;
        margin-top: 32px;
        position: relative;
        font-weight: 700;
    }


    &:hover{


        h3{

            &:after{
                background: lighten(@primary-color, 10%);
                width: 24px;
                left: 50%;
                margin-left: -12px;
            }

        }
        
        figure{

            position: relative;

            img{
                opacity: 1;
            }

            span.fa{
                position: absolute;
                width: 60px;
                height: 60px;
                line-height: 60px;
                text-align: center;
                display: inline-block;
                top: 50%;
                left: 50%;
                margin:  -30px 0 0 -30px;
                font-size: 24px;   
                .transition(all, 0.5s);
                color: @light-text-color;
                background: @primary-color;
                .round(5px);
                transform: scale(1);
            }

        }

    }

}

.service-carousel{

    clear: both;
    
    [class^="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important;
        margin-left: -1px;
        margin-top: -1px;
        width:100%;
    }
    

    .owl-nav {
        clear: both;
        color: #000000;
        line-height: 32px;
        height: 32px;
        margin-top: -14px;
        opacity: 0.9;
        position: absolute;
        top: 50%;
        width: 100%;
        z-index: 1;
        -webkit-transition: all 0.3s ease-in-out ;
        -moz-transition: all 0.3s ease-in-out ;
        transition: all 0.3s ease-in-out ;

        div.owl-prev {
            background: @primary-color;
            text-align: center;
            .transition(all, 0.5s);
            width:32px;
            height: 32px;
            line-height: 32px;
            font-size: 18px;
            color: darken( @light-bg, 5%);
            float: left;
            .round(0px);

            &:hover{
                background: @dark-bg;
            }
        }

        div.owl-next {

            background: @primary-color;
            text-align: center;
            .transition(all, 0.5s);
            width:32px;
            height: 32px;
            line-height: 32px;
            font-size: 18px;
            color: darken( @light-bg, 5%);
            float: right;
            .round(0px);

            &:hover{
                background: @dark-bg;
            }
        }


        &:hover{
            opacity: 0.8;
        }



    }

    .owl-dots{

        margin: 12px auto 0 auto;
        width: 100px;
        text-align: center;

        .owl-dot{
            .round(100px);
            display: inline-block;
            padding: 2px;
            width: 12px;
            height: 12px;
            background: darken( @light-bg,20%);
            margin: 0 2px;

            span{
                display: none;
            }
        }

        .active{
            .round(100px);
            width: 12px;
            background: @primary-color;
            span{
                display: none;
            }

        }

    }

    &:hover{

        .owl-nav{
            opacity: 1;
        }

    }

}

/*-----  PROCESS LAYOUTS ----*/

.process-step-1{
    overflow: hidden;
    padding: 42px 24px;
    margin-top:0px;
    margin-bottom: 24px;
    position:relative;
    background: darken(@pure-white-bg, 1%);
    border: 1px solid lighten(@light-border-color,15%);
    
    .icon{
        display: inline-block;
        font-size: 120px;
        text-align: center;
        transition: all 0.5s ease-out 0s;
        margin-bottom: 24px;
    }

    h3{
        font-size: 28px;
        line-height: 42px;
        color:@text-color;
    }
    
    .process-info{
        margin-bottom: 0px;
    }

    &:hover{
        
        background: darken(@pure-white-bg, 2%);
        border: 1px solid lighten(@light-border-color,10%);

        span{
            color: @text-color;
        }

    }

}

/*----- TEAM LAYOUT ----*/

.section-team-bg{
    background-color: #333333;
    background-image: url("../images/team_bg.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
    
}

.team-layout-1{
    background: @pure-white-bg;
    .transition(all, 0.9s); 
    position: relative;
    overflow: hidden;
    
    .team-member{    
        position:relative;
        overflow:hidden;

        a{
            display: block;

            img{
                .transition(all, 0.9s);  
                vertical-align: middle;  
                transform: scale(1);    
            }
        }

    }

    .team-info{
        padding:20px 16px 12px;
        position:relative;

        h3{
            margin: 12px 0 0 0;
            padding-bottom: 0px;
            font-size: 24px;
            letter-spacing: 0.03em;
            color:@dark-text-color;
            position:relative;
        }

        h4{
            color: @separator-border;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 10px;
        }

        .team-seperator{
            background: @primary-color;
            height: 1px;
            width: 40px;     
            display: inline-block;
            margin: 6px 0 3px;

            .transition(all, 0.9s);     
        }

        .team-social-share{ 
            padding-top: 0px;
            position: relative; 

            a{
                background: darken(@pure-white-bg,3%);
                color: @secondary-color;
                font-size:14px;
                text-align:center;
                display: inline-block;
                height: 32px;
                line-height: 32px;
                width: 32px;     
                margin-bottom: 6px;
                margin: 0 3px 6px;
                border-radius: 100%;
                
                
                &:hover{
                    background: @primary-color;
                    color: @light-text-color;
                }

            }

        } 


    }

    &:hover{
        
        background: darken( @light-bg, .5%);
    
        .team-seperator{
            width: 24px
        }

        .team-member{
            img{
                transform: rotate(5deg) scale(1.07);
            }
        }
    }


}

.team-carousel{
    
    clear: both;
    
    [class^="col-"] {
        width: 100%;
    }

    .owl-nav {
        clear: both;
        color: #000000;
        font-size: 22px;
        line-height: 42px;
        margin-top: -42px;
        opacity: 0.5;
        position: absolute;
        top: 50%;
        width: 100%;
        z-index: 1;
        -webkit-transition: all 0.3s ease-in-out ;
        -moz-transition: all 0.3s ease-in-out ;
        transition: all 0.3s ease-in-out ;

        div.owl-prev {
            text-align: center;
            .transition(all, 0.5s);
            position: relative;
            left: -4px;
            width:42px;
            height: 42px;
            line-height: 42px;
            font-size: 24px;
            background: @primary-color;
            color: darken( @light-bg, 5%);
            float: left;
            .round(4px);
        }

        div.owl-next {
            text-align: center;
            float: right;
            .transition(all, 0.5s);
            position: relative;
            right: -4px;
            width:42px;
            height: 42px;
            line-height: 42px;
            font-size: 24px;
            background: @primary-color;
            color: darken( @light-bg, 5%);
            .round(4px);
        }


        &:hover{
            opacity: 0.8;
        }



    }
    
    .owl-dots{

        margin: 24px 0 0 0;
        text-align: center;

        .owl-dot{

            display: inline-block;
            padding: 2px;
            width: 24px;
            height: 8px;
            background: transparent;
            border:1px solid @light-border-color;
            margin: 0 4px;

            span{
                display: block;
            }
        }

        .active{

            span{
                padding: 0px;
                width: 18px;
                height: 2px;
                background: lighten(@primary-color, 10%);
            }

        }

    }
    
    &:hover{
        
        .owl-nav{
            opacity: 1;
        }
        
    }

}

/*----- COUNTER LAYOUT ----*/

.section-counter-bg{
    background-image: url("../images/counter_bg.jpg");
    background-repeat: repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
}

.counter-block-1{
    border: 1px solid lighten(@light-border-color,10%);
    margin: 0 0 24px 0;
    padding: 32px 24px;
    background: darken(@pure-white-bg,1%);
    .transition(all, 0.5s);

    .fa{
        display:block;
        color: @default-font-color;
        opacity:1;  
        margin-bottom: 12px;
        font-size: 48px;
    }

    p{
        margin-top: 10px;
        font-weight: 600;
        font-size: 20px;
        margin-bottom:0;
        color: @text-color;
        letter-spacing: 0.03em;
        position: relative;
    }

    .counter{
        display: inline-block;
        margin-top: 12px;
        font-size: 32px;
        color: @primary-color;
        font-weight: 600;
    }

    .counter-postfix{
        display: inline-block;
        margin-top: 0px;
        font-size: 32px;
        color: @primary-color;
        font-weight: 600;
    }

    &:hover{
        
        border: 1px solid @light-border-color;
        background: darken(@pure-white-bg,1.5%);

        .fa{
            opacity:0.9;  
        }
    }

}

/*----- PRICING TABLE LAYOUTS ----*/

.section-pricing-table-bg{
    background-color: #f6f6f6;
    background-image: url("../images/pricing_table_bg.jpg");
    background-repeat: no-repeat;
    background-position: right top;
    position: relative;
    overflow: hidden;
}

.price-table-layout-1{
    background: darken(@pure-white-bg, 1%);
    border: 2px solid #e3e3e3;
    text-align: center;
    position: relative;
    padding-bottom: 42px;
    margin-bottom: 24px;
    padding-top: 42px;
    transform: translateZ(0px);

    &:before,
        &:after{
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        bottom: -2px;
        right: -2px;
        z-index: -1;
        transition: all 0.5s ease 0s;
    }

    &:before{
        border-right: 2px solid  @primary-color;
        border-left: 2px solid  @primary-color;
        transform: scaleY(0);
        transform-origin: 100% 0 0;
    }

    &:after{
        border-bottom: 2px solid  @primary-color;
        border-top: 2px solid  @primary-color;
        transform: scaleX(0);
        transform-origin: 0 100% 0;
    }

    &:hover{

        &:before{
            transform: scaleY(.5);
        }

        &:after{
            transform: scaleX(.5);
        }

    }

    .price-table-header{
        color: @text-color;
        padding: 0 0 12px 0;
    }

    .heading{
        font-size: 22px;
        font-weight: 600;
        margin: 0 0 5px 0;
        color: @primary-color;
    }
    
    .pricing-table-img{
   
        background: transparent;
    }

    h2.title{
        font-size: 32px;
        line-height: 42px;
        letter-spacing: 0.03em;
        margin-top: 16px;
        margin-bottom: 24px;
        font-weight: 500;
    }

    .subtitle{
        display: block;
        margin-bottom: 12px;
        margin-top: 12px;
        font-size: 12px;
    }

    .price-value{
        font-size: 52px;
        font-weight: 800;
        margin-top: 12px;
        position: relative;
        display: inline-block;
        color: #FFBD0F;
    }

    .currency{
        font-size: 26px;
        font-weight: normal;
        position: absolute;
        top: 0px;
        left: -24px;
        color: @text-color;
       
    }
    .month{
        font-size: 20px;
        position: absolute;
        bottom: 17px;
        right: -40px;
    }
    .pricing-content{
        list-style: none;
        padding: 12px 0 0 0;
        margin-bottom: 12px;

        li{
            line-height: 42px;
            font-weight: 400;
        }
        
        
    }

    .price-plan-btn{
        display: inline-block;
        border: 0px solid @secondary-color;
        .round(32px);
        font-size: 14px;
        font-weight: 700;
        padding: 12px 32px;
        position: relative;
        text-transform: uppercase;
        transition: all 0.3s ease 0s;
        margin-top: 24px;
        background: @secondary-color;
        color: @light-text-color;

        &:hover{
            border-color: @primary-color;
            color:  @primary-color;
        }
    }
}

.price-table-layout-highlight {
    border: 2px solid  @secondary-color;
    
    .pricing-table-img{
        .round(100%);
        padding: 12px;
        background: @secondary-color;
        border: 0px solid @secondary-color;
    }
    
    .price-plan-btn{
        
         background: @primary-color;
        color: @light-text-color;

        &:hover{
            
            border: 0px solid @secondary-color;
            color:  @secondary-color;
        }
    }
    
    
}

/*----- TESTIMONIAL LAYOUTS ----*/

.section-testimonial-bg{
    background-image: url("../images/testimonial_bg.jpg");
    background-repeat: repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
}

.testimony-layout-1{
    background:@pure-white-bg; 
    position: relative;
    padding: 24px 24px 24px;
    margin: 0px;

    img{
        .round(100%);
        height:70px;
        max-width:70px;
        margin: 0 auto 12px;
        border: 1px solid @light-border-color;
        padding: 2px;
    }
    
    .testimony-info{
        overflow: hidden;
        padding: 0;
    }

    h4{
        font-size: 20px;
        line-height: 32px;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing:0.03em;
        margin: 0;
    }

    h6{
        color: darken( @light-text-color,45%);
        line-height: 18px;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 0;
        text-transform: uppercase;
    }


    span{
        font-size: 14px;
        text-transform: uppercase;
        color: lighten(@default-font-color, 10%);
    }

    p.testimony-text{
        clear: both;
        font-style: italic;
        position: relative;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        margin: 0 0 24px 0;
        padding-top: 12px;
        color: @default-font-color;
        
        &:before{
            background: transparent;
            color: @secondary-color;
            content: '\f10d';
            display: block;
            font-family: FontAwesome;
            font-size: 70px;
            font-style: normal;
            height: 48px;
            left: 0;
            opacity: 0.1;
            margin-left: 0px;
            line-height: 48px;
            position: absolute;
            text-align: center;
            top: 6px;
            width: 48px;  
        }
    }

}

.testimonial-container.owl-carousel{
    [class^="col-"] {
        width: 100%;
    }

}

.testimonial-container{
    
    clear: both;

    .owl-nav {
        clear: both;
        color: #000000;
        font-size: 22px;
        line-height: 42px;
        opacity: 0;
        position: absolute;
        top: 50%;
        width: 100%;
        margin-top: -21px;
        z-index: 1;
        .transition(all, 0.5s);

        div.owl-prev {
            text-align: center;
            .transition(all, 0.5s);
            position: relative;
            left: -8px;
            width:42px;
            height: 42px;
            line-height: 42px;
            font-size: 24px;
            background: @primary-color;
            color: darken( @light-bg, 5%);
            float: left;
            .round(100%);
        }

        div.owl-next {
            text-align: center;
            float: right;
            .transition(all, 0.5s);
            position: relative;
            right: -8px;
            width:42px;
            height: 42px;
            line-height: 42px;
            font-size: 24px;
            background: @primary-color;
            color: darken( @light-bg, 5%);
            .round(100%);
        }


        &:hover{
            opacity: 0.8;
        }



    }
    
    .owl-dots{

        margin: 24px 0 0 0;
        text-align: center;

        .owl-dot{

            display: inline-block;
            padding: 2px;
            width: 12px;
            height: 12px;
            .round(100%);
            background: transparent;
            border:1px solid @light-border-color;
            margin: 0 4px;

            span{
                display: block;
            }
        }

        .active{

            span{
                .round(100%);
                padding: 0px;
                width: 6px;
                height: 6px;
                background: lighten(@primary-color, 10%);
            }

        }

    }
    
    &:hover{
        
        .owl-nav{
            opacity: 1;
        }
        
    }

}

/*----- NEWSLETTER LAYOUT 01 ----*/

.horizontal-newsletter{
    position:relative;
    overflow:hidden;
    margin-top: 0px;
    margin-bottom: 12px;

    .news-subscription{

        input {
            background: @pure-white-bg;
            border:2px solid @light-border-color;
            .round(32px);
            color: @text-color;
            height: 55px;
            padding-left: 13px;
            width: 100%;
        }

        button {
            background: @primary-color;
            border: 0 none;
            .round(32px);
            color: @light-text-color;
            font-size: 18px;
            font-weight: 500;
            text-transform:uppercase;
            height: 55px;
            width:160px;
            position:absolute;
            right:0;


            &:hover{
                background: darken(@primary-color, 20%);

                &:before{
                    background: darken(@primary-color, 20%);
                }

            }  
        }


    }

}

/*----- HOME BLOG LAYOUT 01 ----*/

.latest-blog{
    clear: both;
    margin-bottom: 24px;
    background: darken(@pure-white-bg, .5%);

}

.latest-thumbnail{
    clear: both;
}


a.latest-date{
    border: 2px solid @primary-color;
    border-radius: 20px;
    color: black;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 0.5px;
    margin-bottom: 15px;
    padding: 2px 20px 0;
    text-decoration: none;
    transition: all 0.3s ease 0s;
}  

.news-content{
    padding:0 12px 20px;   
    overflow: hidden;
}

.latest-title {
    font-weight: 500;
    font-size: 24px;
    line-height:28px;
    margin-top: 24px;

    a {
        color:@heading-font-color;

        &:hover {
            color: @primary-color;

        }                      
    }                 
}

.latest-details {
    font-size: 14px;
    font-weight: 400;
    margin-top: 0;

    display:inline-block;
    padding: 0px;


    a {
        border-right: 1px solid @primary-color;
        color: @dark-text-color;
        display: inline-block;
        line-height: 14px;
        margin-right: 3px;
        padding-right: 8px;
        transition: all 0.3s ease 0s;

        &:last-child {
            border-right: medium none;
            margin-right: 0;
            padding-right: 0;
        }

        &:hover {
            color: @dark-text-color;
        }                    
    }              
} 

.blog-details{

    a{
        text-transform: uppercase;
        margin-left: 60px;
        font-size:15px;
        color:@text-color;

        &::after{
            background-color:@primary-color;
            bottom: 10px;
            content: "";
            height: 2px;
            left: 20px;
            position: absolute;
            width: 40px;

        }

        &:hover{
            color:@primary-color;   
        }

    }

}

/*----- CTA LAYOUTS ----*/

.section-cta-bg{
    background: @secondary-color;
}

.cta-layout-01{

    clear: both;

    h4{
        font-size: 60px;
        line-height: 72px;
        margin-bottom: 24px;
        font-weight:600;
        text-transform: uppercase;
    }

    h4.dark-text-color{
        color: @text-color;
    }


}


/*----- SECTION APPOINTMENT ----*/

.section-appointment-bg{
    background-image: url("../images/appointment_bg.jpg");
    background-repeat: repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
    background-color: #FAFAFA;
}
 
.appointment-form-wrapper{

    background: @pure-white-bg;
    padding: 0px 0px 28px;
    margin-bottom: 100px;
    border: 1px solid lighten( @light-border-color, 15%);
    .drop-shadow( 0px,  0px, 25px, 10px, .05 );

    .appointment-form-heading{
        position:relative; 
        background:@primary-color;
        padding: 30px;
        margin: -1px -1px 48px -1px;

        p.form-title{
            position:relative; 
            clear: both;
            overflow: hidden;
            font-size: 24px;
            font-weight: 600;
            color:@light-text-color;
            letter-spacing:0.03em;
            margin: 0;
            text-transform:uppercase;
        }
    }

    .appoinment-form{

        padding: 0 12px;

        .form-control{
            font-size:14px;
        } 

        .form-group{
            margin-bottom:0;
            position: relative;

            i{
                position: absolute;
                top: 12px;
                right: 32px;
                font-size: 17px;
                color: darken(@light-text-color,10%);
                transition : all 0.5s ease 0s;
            }
        }
        
        .form-control::-moz-placeholder{
            color: lighten( @text-color, 25%);
            font-size: 14px;
            text-transform: uppercase;
        }
        

        input[type=text],
        input[type=email],
        input[type=url],
        input[type=password]{
            color: lighten( @text-color, 25%);
            border: 1px solid lighten( @light-border-color, 12%);
            line-height: 42px;
            padding-right: 32px;
            height: 42px;
            margin-bottom: 24px;
            .round(0);
            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);
            .transition( 0.3s);
            
            &:focus{

                border: 1px solid @light-border-color;
                .drop-shadow(0,0,0,0);
                .inner-shadow(0,0,0,0);

            }

        }


        textarea{
            color: lighten( @text-color, 25%);
            border: 0px;
            border: 1px solid lighten( @light-border-color, 12%);
            padding-left: 2px;
            margin-bottom: 24px;
            padding:6px 12px;
            .round(0);
            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);
            .transition( 0.3s);
            &:focus{

                .drop-shadow(0,0,0,0);
                .inner-shadow(0,0,0,0);
                border: 1px solid @light-border-color;

            }

        }

        select{
            border: 0px;
            border: 1px solid lighten( @light-border-color, 12%);
            padding-left: 2px;
            margin-bottom: 24px;
            padding:6px 12px;
            .round(0);
            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);
            .transition( 0.3s);
            &:focus{
                .drop-shadow(0,0,0,0);
                .inner-shadow(0,0,0,0);
                border: 1px solid @light-border-color;

            }

        }

        .select-style{

            position:relative;       

            select.form-control{
                border: 1px solid lighten( @light-border-color, 12%);
                -webkit-appearance: none;
                -moz-appearance: none;
                position:relative;
                background-image:url("../images/down_arrow.png");
                background-position:100% 4px;
                background-repeat: no-repeat;
                cursor:pointer;
                box-shadow:none;
                color: lighten( @text-color, 25%);
                padding:6px 12px;
                height:42px;
                line-height:32px;
                margin-bottom:24px;
                font-size: 14px;
            }
        }

    }


}

.appoinment-person{
    position: relative; 
    top: 12px;
}

// CLIENT SPONSORS

.section-client-logo{
    clear:both;
}

.logo-layout-1{
    
    clear: both;

    .client-logo{
        
        padding: 16px;
        margin-bottom: 32px;
        border: 1px solid @light-border-color;

        img{
            .round(0);
            width: 100%;
            margin:0;
            display: block;
            opacity: 0.7;
            .transition(all, 0.5s);
        }

        &:hover{
            
            border: 1px dotted lighten( @primary-color, 10%);
            img{
                opacity: 1;
            }

        }

    }

}

.no-border-logo-layout{
    
    .client-logo{
        
        border: 0px solid transparent;
        
        &:hover{
            
            border: 0px solid transparent;
        }
            
        
    }
    
}
    
.logo-items{
    
    clear: both;
    [class^="col-"] {
        width: 100%;
    }
    
    .client-logo{
        margin-bottom: 0px;
    }
    
    .owl-nav {
        clear: both;
        color: #000000;
        line-height: 32px;
        height: 32px;
        margin-top: -14px;
        opacity: 0.5;
        position: absolute;
        top: 50%;
        width: 100%;
        z-index: 1;
        -webkit-transition: all 0.3s ease-in-out ;
        -moz-transition: all 0.3s ease-in-out ;
        transition: all 0.3s ease-in-out ;

        div.owl-prev {
            background: @dark-bg;
            text-align: center;
            .transition(all, 0.5s);
            width:32px;
            height: 32px;
            line-height: 32px;
            font-size: 18px;
            color: darken( @light-bg, 5%);
            float: left;
            .round(0px);
            
            &:hover{
                background: @primary-color;
            }
        }

        div.owl-next {

            background: @dark-bg;
            text-align: center;
            .transition(all, 0.5s);
            width:32px;
            height: 32px;
            line-height: 32px;
            font-size: 18px;
            color: darken( @light-bg, 5%);
            float: right;
            .round(0px);

            &:hover{
                background: @primary-color;
            }
        }


        &:hover{
            opacity: 0.8;
        }



    }
    
    .owl-dots{

        margin: 12px auto 0 auto;
        width: 100px;
        text-align: center;

        .owl-dot{
            .round(100%);
            display: inline-block;
            padding: 2px;
            width: 12px;
            height: 12px;
            background: darken( @light-bg,20%);
            margin: 0 2px;

            span{
                display: none;
            }
        }

        .active{
            .round(100%);
            width: 12px;
            height: 12px;
            background: @primary-color;
            span{
                display: none;
            }

        }

    }
    
    &:hover{
        
        .owl-nav{
            opacity: 1;
        }
        
    }

}

/***********************************************************************************************/
/*  HOME PAGE 02 */
/***********************************************************************************************/

// SECTION BANNER

.section-banner{
    padding: 280px 0px 250px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
}

.banner-content{

    width: 100%;    

    h2 {
        position: relative;
        font-size: 72px;
        line-height: 72px;
        font-weight: 700;
        color: @light-text-color;

        span{
            padding: 0 3px;
            font-weight:700;
            color: lighten(@primary-color,15%);
        }

    }

    h3 {
        font-family: @slider-font;
        color: @light-text-color;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
    }

    .slider-button{
        display:block;
        margin-top:30px;

        a.btn-slider {
            font-size: 16px;
            display: inline-block;
            background: @primary-color;
            border: 2px solid @primary-color;
            color: @light-text-color;
            line-height: 42px;
            padding: 6px 24px;
            text-transform:uppercase;
            font-weight: 600;
            text-align: center;
            letter-spacing: 0em;
            .round(32px);

            &:hover{
                background: darken(@primary-color,10%)
            }

        } 

    }

}

/***********************************************************************************************/
/* MAIN CONTENT */
/***********************************************************************************************/

.main-content{
    margin: 48px 0;
}

//SECTION SHARE

.btn-social-icon{
    height: 42px;
    width: 42px;
    border: 0;
    border-radius: 0px !important;
    margin: 3px 3px;
}

.btn-social-icon .fa{
    line-height: 42px;
    color: @light-text-color;
}

.btn-goggle-plus {
    background-color: #26b84b;
}

.btn-goggle-plus:hover {
    background-color: #20993e;
}

.btn-envelope-o {
    background-color: #d74b3e;
}

.btn-envelope-o:hover {
    background-color: #ad3d32;
}


.section-transparent-bg{
    background: transparent !important;
}

/***********************************************************************************************/
/* 05. BLOG LAYOUT */
/***********************************************************************************************/

.single-post{

    margin-bottom: 50px;

    p{
        line-height: 30px;
    }

    .single-post-content{
        margin-bottom: 30px;
        padding: 6px;
        background: @blog-bg-color;
    }

    .single-post-title{
        line-height: 24px;

        h2{
            font-size: 28px;
            line-height: 32px;
            line-height: 30px;
            font-weight: 500;
        }

        .single-post-meta{
            font-size: 15px;
        }
    }

}

.readmore{
    border:0px;
    background: @primary-color;
    color: @light-text-color;
    .round(0px);
    padding: 12px 24px;
}

.readmore-sm{
    border:0px;
    background: @primary-color;
    color: @light-text-color;
    .round(0px);
    padding: 6px 12px;
}

/***********************************************************************************************/
/* 06. BLOG SINGLE PAGE */
/***********************************************************************************************/

.single-post-inner{
    background: @blog-bg-color;
    padding: 30px;
}

.post-inner-featured-content{
    display: block;

    img{
        display: block;
        width: 100%;
        text-align: center;
    }
}

.single-post-inner-title{

    h2{
        font-size: 32px;
        margin: 32px 0 24px 0;
    }
    margin-bottom: 24px;
}

.single-post-inner-meta{

    h2{
        font-size: 24px;
    }

    .tag-list{
        a{
            display: inline-block;
            padding: 4px 12px;
            margin-right: 3px;
            background: @primary-color;
            color: @light-text-color;
        }
    }
}

.custom-post-info{margin:0; padding:0;}

.custom-post-thumb-small{width: 70px; height: 70px; }
.custom-post-thumb-medium{width: 350px; height: 120px;}

.single-post-container{

    margin: 0 0 24px 0;

    h3{
        margin: 24px 0 12px 0;
        font-size: 18px;
        text-transform: capitalize;

        a{
            color: @heading-font-color;

            &:hover{
                color: @text-color;
            }
        }
    }

    .custom-breadcrumb{

        font-size: 18px;        
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid @light-border-color;

    }


    .article-meta-extra{

        border-top: 1px solid @light-border-color;
        border-bottom: 1px solid @light-border-color;
        padding: 6px 0;
        margin-bottom: 12px;

        span{

            margin-right: 5px;

            &:last-child{
                margin-right: 0px;
            }

        }


        i{
            color: @sub-text-color;
            margin-right: 3px;
        }

    }


    h2+p{

        margin-top: 12px;
    }


}

.single-post-content{

    h1{
        margin: 12px 0;
    }

    h2{

        margin: 12px 0;

    }

    h3{
        margin: 12px 0;
    }

    h4{

        margin: 12px 0;

    }

    h5{
        margin: 12px 0;
    }

    h6{
        margin: 12px 0;

    }

}

.articles-nav{
    margin-top: -1.5em;
    margin-bottom: 1.5em;
    padding: 0 2.4em;

    .articles-nav-prev{
        float: left;
    }

    .articles-nav-next{
        float: right;
    }

}

.aligncenter, .alignleft, .alignright, .alignnone {

    margin: 12px 0 24px 0;
    max-width: 100%;
    height: auto; 

}

.aligncenter, img.centered {

    display: block;
    margin: 12px auto;
}


.alignleft {
    float: left;
}

.alignright {
    float: right;

}

.alignnone {
    clear:both;
}

.wp-caption{

    text-align:center;

    img{

        max-width: 100%;
        max-height: auto;

    } 

    .wp-cap{
        margin-bottom: 0px;
        font-size: 12px;

    }
}

.post-navigation {

    text-align:center;
    clear: both;

    p {

        text-transform: uppercase;

        a{

            position: relative;
            padding-left: 24px;
            margin-right: 5px;

            &:before{

                content:"";
                position:absolute;
                width:12px;
                height:12px;
                top:4px;
                left:3px;
                background: transparent;
                border: 1px solid @light-border-color;   
                .transition( 0.3s);

            }

            &:hover{

                &:before{

                    background: @primary-color;
                    border: 1px solid @text-color;   

                }


            }

            &:first-child{
                margin-right: 0px;
            }

        }

    }

}

.comments-nav-section,
.post-nav-section{
    margin: 24px 0 0 0;

    p {
        margin-bottom: 0px;
    }

    a{

        .round(0px);
        text-align: center;
        .transition(0.3s);
        border: 1px solid @light-border-color;
        .drop-shadow(0, 1px, 1px, 0, 0);
        background: transparent;
        color: @text-color;
        padding: 6px 12px;

        &:hover{
            border: 1px solid @primary-color;
            color: @primary-color;
            background: transparent;
        }

    }

}

.article-share-section{
    margin: 24px 0 0 0;
    border: 1px solid @light-border-color;
    border-left: 0px;
    border-right: 0px;
    padding: 14px 0 10px 0;
}

.article-author {
    margin-top: 24px;    
    background: @blog-bg-color;
    padding: 30px;
    min-height: 100px;
    padding-bottom: 24px;


    .author-avatar img {
        width: 100px;
        max-width: 100%;
        max-height: auto;
        float: right; 
        border: 1px solid @light-border-color;
        padding: 3px;
        margin-top: 0px;
        margin-left: 12px;
    }

    .about_author{
        margin-bottom: 24px;
    }

    .social-icons{
        a{
            display: inline-block;
            color: @primary-color;
            margin-right: 15px;

            &:hover{
                color: @primary-color;
            }
        }
    }


}

.related-post {
    margin-top: 24px;    
    background: @blog-bg-color;
    padding: 30px;    

    ul {

        li {
            margin: 5px 0;
            a {

                position: relative;                      
                .transition( 0.3s);
                color: @default-font-color;

                &:hover {

                    color: @primary-color;

                }


            }


        }
    }
}

// PAGINATION.

ul.pagination {

    li {


        a {
            color: @primary-color;
            .round(0px);
            padding: 6px 12px;
            border: 0px;
            margin: 0 2px;

            &:hover{
                backgroud: #000 !important;
            }
        }


        a.current{
            background: @primary-color;
            color: @light-text-color;
            border: 0px;
        } 


        &:first-child {
            .round(0px);
            a {

            }
        }

        &:last-child {

            .round(0px);
            a {
            }
        }
    }

}

// COMMENT LISTS

.comments-area {

    margin-top: 24px;    
    background: @blog-bg-color;
    padding: 30px;

}
.article-add-comments {
    float: right;
    display: inline-block;	
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align:center;
    color:@sub-text-color;		
    border: 1px solid @light-border-color;
    background: @background-color;
    .transition(0.3s);

    &:hover {
        border: 1px solid @primary-color;
        color: @primary-color;

    }


}

.commentslist {

    margin-top: 24px;
    counter-reset: comment-id;

    li{
        list-style-type : none;

        margin-left: 0px;

        &:first-child{

            margin-top: 24px;

        }

    }



    li+li{
        margin-top: 24px;
    }

    li ol, li ul {
        padding-left: 3em;
        margin-left: 3em;
        border-left: 1px dotted @light-border-color;
        list-style-type : none;
    }

    li ol li, li ul li{
        position: relative;
        &::before {
            content: '';
            width: 2em;
            height: 1px;
            border-bottom: 1px dotted @light-border-color;
            position: absolute;
            left: -3em;
            top: 2em;
        }
    }

    li article {
        background: @background-color;
        padding: 12px;


        header { 
            border-bottom: 1px solid @light-border-color;
            font-size:12px;
            margin-bottom: 1.5em;
            position: relative;

            span {				
                color: lighten(@text-color,30%);
            }

            &::before {
                counter-increment: comment-id;
                content: counter(comment-id);
                position: absolute;
                right:5px;
                top: 15px;
                color: @light-color;
                font-size: 32px;
                font-family: @heading-font;
            }

            h5 {
                margin-bottom: 12px;
                text-transform: capitalize;
                color: @heading-font-color;

                a{
                    color: @heading-font-color;
                    &:hover{
                        color: @text-color;
                    }
                }     

                span {
                    font-size: 12px;
                    background: @dark-color;					
                    color: @light-text-color;
                    padding: 3px 5px;
                    font-style: normal;
                    margin-right:.5em;

                }
            }

        }

        .comment_text{
            padding-right: 24px;
        }
    }

}


.comment-avatar img {
    width: 64px;
    height: 64px;
    float: right;
    background: #FFFFFF;                
    margin: 2px;
    border: 1px solid @light-border-color;
    padding: 2px;
    .transition(0.3s);


    &:hover{
        border-color: darken(@light-border-color, 15%);
    }
}  

.waiting-moderation {
    color: lighten(@text-color,30%);

}

.comments-nav-section{
    margin: 48px 0;

    .btn-primary{
        background: @pure-white-bg;

        &:hover{
            background: @pure-white-bg;
        }
    }
}


// COMMENT FROM

#comment-form{

    label{
        margin-bottom: 12px;
    }

    input[type=text],
    input[type=email],
    input[type=url],
    input[type=password]{
        border: 0px;
        border-bottom: 1px solid @border-color;
        line-height: 42px;
        height: 42px;
        margin-bottom: 24px;
        padding: 0 12px;
        .round(0);
        .drop-shadow(0,0,0,0);
        .inner-shadow(0,0,0,0);
        .transition( 0.3s);
        &:focus{
            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);

            border-bottom: 1px solid lighten(@dark-border-color,50%);

        }

    }


    textarea{
        border: 0px;
        border-bottom: 1px solid @border-color;
        padding: 12px;
        margin-bottom: 24px;
        .round(0);
        .drop-shadow(0,0,0,0);
        .inner-shadow(0,0,0,0);
        .transition( 0.3s);
        &:focus{

            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);
            border: 0px;
            border-bottom: 1px solid lighten(@dark-border-color,50%);

        }

    }

}

/***********************************************************************************************/
/* PAGE HEADR AND BREADCRUMB */
/***********************************************************************************************/

.page-header{
    margin: 0;
    padding: 80px 0 80px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-attachment: fixed;
    background-position:center center;
    background-color: transparent;
    background-image: url('../images/header-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border: 0px solid #FFF;

    &:before{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(240,240,240,0.9);
        top: 0;
        left: 0;
        content: '';
        z-index: -1;
    }

    h3{
        color: @secondary-color;
        text-align: center;
        font-size: 36px;
        line-height: 24px;
        font-weight: 500;
        margin: 24px 0;
        text-transform: uppercase;
        letter-spacing: 0.02em;
    }

    .page-breadcrumb{
        font-size: 16px;
        color: @default-font-color;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.01em;

        a{

            color: @default-font-color;

            &:hover{
                color: @default-font-color;
            }
        }
    }

}

/***********************************************************************************************/
/* SINGLE TEAM PAGE */
/***********************************************************************************************/

.single-team{
    background: transparent;
    margin: 20px 0 30px;
    overflow: hidden;
    position: relative;
    img{
        width:100%;
    }
}

.single-team-details{

    h3 {
        font-family:@heading-font;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.03em;
        margin: 12px 0 0;
        text-transform: uppercase;
    }

    h4 {
        color: lighten(@separator-border,10%);
        font-family: @heading-font;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.03em;
        text-transform: uppercase;
    }

    ul.team-social-share-2 {
        li {
            background: @primary-color;
            border-radius: 0;
            color: @light-text-color;
            display: inline-block;
            height: 40px;
            line-height: 40px;
            margin-bottom: 20px;
            margin-right: 15px;
            text-align: center;
            width: 40px;

            a{
                color: @light-text-color;   
            }
        }
    }

    a.btn-team-details {
        border: 2px solid @primary-color;
        border-radius: 0;
        margin: 10px 0;
        padding: 10px 15px;
        text-transform: uppercase;
    }
}

/***********************************************************************************************/
/* SERVICE PAGE */
/***********************************************************************************************/

.service-bottom-layout{
    margin-top:30px;
}    

.single-service-content{
    h3{
        font-size:25px;
        text-transform:uppercase;
        font-weight:600;
        letter-spacing:0.03em;
        margin-top:15px;
    } 

    ul{

        list-style-type: none;
        margin-bottom: 16px;
        overflow:hidden;

        li{
            padding-left: 32px;
            position: relative;

            &:before{
                position: absolute;
                content: '\f00c';
                font-family: FontAwesome;
                display: inline-block;
                color: @primary-color;
                margin: 0 11px 0 0;
                position: absolute;
                left: 0px;
            }
        }


    }

}


/***********************************************************************************************/
/*  FAQ PAGE */
/***********************************************************************************************/

.faq-layout{
    position:relative;
    overflow:hidden;

    .panel{
        box-shadow:none;   
    }

    .panel-default{
        position: relative;
        border: 0px solid @border-color;
        padding: 17px 16px 0;
        background: darken( @light-bg, 1%);
        border-radius: 0;
    }

    .faq-box{

        .panel-heading{
            background:none;
            border:0 none;
            display:block;
            padding: 16px;
            margin: -16px -15px;
            background: @primary-color;
            .transition(all, 0.5s);
            .round(0px);

            &:hover{
                background: @dark-bg;
            }
        }

        .panel-title {
            display:block;
            letter-spacing: 0.03em;
            margin-bottom: 0;
            margin-top: 0;
            font-size:inherit;
            text-transform:uppercase;
            color: @light-text-color;
        }    
        .panel-title >a{
            line-height: 24px;
            display:block;
            color: @light-text-color;

            &:hover,
                &:focus{

                outline:none;
                color: @light-text-color;
            }
        }

        .panel-body{
            padding-top: 32px;
            padding-left:0px;   
        }

        .panel-heading [data-toggle="collapse"]:after {
            font-family: 'FontAwesome';        
            content: '\f0d8';
            float: left;
            color: @light-text-color;
            font-size: 16px;
            margin-right:10px;   
        }

        .panel-heading [data-toggle="collapse"].collapsed:after {
            content: '\f0d7';
        }

    }

}

/***********************************************************************************************/
/*  COMING SOON PAGE */
/***********************************************************************************************/

.section-coming-countdown-bg{
    background: transparent;
    background-image: url('../images/coming-soon.jpg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    background-attachment: fixed;
    display: block;
    clear:both;
    position: relative;
    overflow: hidden;
    padding: 220px 0;
}

#coming_countdown{
    font-size: 32px;
    clear: both;
    width: 900px;
    max-width: 100%;
    margin: 30px auto;

    .countdown_container{

        text-align: center;
        display: block;
        float: left;
        width: 25%;

        span.count_number{
            display: block;
            font-size: 90px;
            line-height: 100px;
            font-weight: 600;
            font-family: @heading-font;
            color: @light-text-color;
        }
        span.count_text{
            display: block;                
            font-size: 32px;
            line-height: 42px;
            font-weight: 400;
            font-family: @body-font;
            color: @light-text-color;
            text-transform: uppercase;
        }

    }


}
    

/***********************************************************************************************/
/* 09. GALLERY PAGE */
/***********************************************************************************************/

.section-gallery-bg{
    background-image: url("../images/gallery_bg.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: initial;
    position: relative;
    overflow: hidden;
}


.section-gallery-block{
    clear: both;
}

.gallery-container{
    padding: 12px;
    margin: 0;
}

.no-bottom-padding{
    padding-bottom: 0px;
}

.no-padding-gallery{

    .gallery-container{
        padding: 0px;
        margin: 0;

    }
}

.gallery-light-box{

    display: block;
    overflow: hidden;
    position: relative;

    .gallery-img{

        display: block;
        position: relative;
        max-width: 100%;
        padding: 0px;

        &:before{
            background: rgba(0, 0, 0, 0.3);
            width: 100%;
            height: 0px;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            .transition(all, 0.5s);
        }

        &:after{
            font-family: FontAwesome;
            content: '\f1c5';
            position: absolute;
            right: 150%;
            margin-left: -21px;
            bottom: -64px;
            background: @light-text-color;
            color: @primary-color;
            text-align: center;
            width: 42px;
            height: 42px;
            line-height: 42px;
            .round(0px);
            .transition(all, 0.9s);

        }

        img{
            width: 100%;
            max-width: 100%;
            display: block;
            opacity: 1;
            .transition(all, 0.7s);
        }

    }

    &:hover{


        .gallery-img{

            &:before{
                background: rgba(0, 0, 0, 0.7);
                width: 100%;
                height: 1500px;
            }

            &:after{
                opacity: 1;
                top: 50%;
                left: 50%;
                margin-top: -24px;
                .round(4px);
            }

            img{

                opacity: 0.4;
            }

            figcaption{
                bottom: 0px;
                left: 0;
            }

        }



    }




}

.gallery-carousel{

    clear: both;
    
    [class^="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important;
        margin-left: -1px;
        margin-top: -1px;
        width:100%;
    }
    

    .owl-nav {
        clear: both;
        color: #000000;
        line-height: 32px;
        height: 32px;
        margin-top: -14px;
        opacity: 0.9;
        position: absolute;
        top: 50%;
        width: 100%;
        z-index: 1;
        -webkit-transition: all 0.3s ease-in-out ;
        -moz-transition: all 0.3s ease-in-out ;
        transition: all 0.3s ease-in-out ;

        div.owl-prev {
            background: @primary-color;
            text-align: center;
            .transition(all, 0.5s);
            width:32px;
            height: 32px;
            line-height: 32px;
            font-size: 18px;
            color: darken( @light-bg, 5%);
            float: left;
            .round(0px);

            &:hover{
                background: @dark-bg;
            }
        }

        div.owl-next {

            background: @primary-color;
            text-align: center;
            .transition(all, 0.5s);
            width:32px;
            height: 32px;
            line-height: 32px;
            font-size: 18px;
            color: darken( @light-bg, 5%);
            float: right;
            .round(0px);

            &:hover{
                background: @dark-bg;
            }
        }


        &:hover{
            opacity: 0.8;
        }



    }

    .owl-dots{

        margin: 12px auto 0 auto;
        width: 100px;
        text-align: center;

        .owl-dot{
            .round(100px);
            display: inline-block;
            padding: 2px;
            width: 12px;
            height: 12px;
            background: darken( @light-bg,20%);
            margin: 0 2px;

            span{
                display: none;
            }
        }

        .active{
            .round(100px);
            width: 12px;
            background: @primary-color;
            span{
                display: none;
            }

        }

    }

    &:hover{

        .owl-nav{
            opacity: 1;
        }

    }

}  

/***********************************************************************************************/
/* 11. 404 PAGE */
/***********************************************************************************************/

.section-404{
    background: @second-bg-color;
}

.message-container-404{

    margin: 48px 0;

    .text-404{
        font-size: 180px;
        line-height: 200px;
        font-weight: 600;
        letter-spacing: 0.1em;
        font-family: @heading-font-alt;
        color:@primary-color;

        span {
            color: darken(@primary-color,10%);
        }
    }

    .message-text-404{
        font-size: 32px;
        line-height: 42px;
        display: block;
    }

}

/***********************************************************************************************/
/* CONTACT PAGE */
/***********************************************************************************************/

.section-contact-block{

    position: relative;
    overflow: hidden;

    .content-block{
        padding:  0 48px 0 0;
    }

    .contact-title{
        font-size: 24px;
        border-bottom: 3px solid #eeeeee;
        color: #5c5c5c;
        padding-bottom: 15px;
        position: relative;

        &:after {
            bottom: -3px;
            content: "";
            height: 3px;
            left: 0;
            position: absolute;
            width: 32px;
            background: @primary-color;
        }

    }


    .contact-info{

        li{
            margin-bottom: 24px;
        }

        .icon-container{
            display: inline-block;
            background: @primary-color;
            color: @light-text-color;
            width: 32px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            text-align: center;
            margin-right: 5px;

        }

        address{
            display: inline-block;
        }

        a{

            color: @default-font-color;

            &:hover{
                color: @primary-color;
            }

        }
    }

    // Contact Form

    .contact-form-block{

        padding: 0 0 0 0px;

    }

    #contact-form{

        input[type=text],
        input[type=email],
        input[type=url],
        input[type=password]{
            border: 0px;
            border-bottom: 1px solid @border-color;
            line-height: 42px;
            height: 42px;
            margin-bottom: 24px;
            padding-left: 2px;
            .round(0);
            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);
            .transition( 0.3s);
            &:focus{

                .drop-shadow(0,0,0,0);
                .inner-shadow(0,0,0,0);
                border: 0px;
                border-bottom: 1px solid lighten(@dark-border-color,50%);

            }

        }


        textarea{
            border: 0px;
            border-bottom: 1px solid @border-color;
            padding-left: 2px;
            margin-bottom: 24px;
            .round(0);
            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);
            .transition( 0.3s);
            &:focus{

                .drop-shadow(0,0,0,0);
                .inner-shadow(0,0,0,0);
                border: 0px;
                border-bottom: 1px solid lighten(@dark-border-color,50%);

            }

        }

    }


}

/***********************************************************************************************/
/* 12. FOOTER */
/***********************************************************************************************/

footer {
    border-top: 1px solid lighten(@light-border-color,15%);
    background: @light-bg;
    

    p{
        color: @default-font-color;
    } 

    a{
        color: @default-font-color;

        &:hover{
            color:lighten(@default-font-color,10%);
        }
    }    
    .footer-logo{
        
        text-align: center;

        &:after{
            background:@light-border-color;
            content:"";
            position:absolute;
            height:48px;
            top:10px;
            width:1px;
            right:28px;

        }
    }    

    .footer-intro{
        margin-bottom: 32px;
    }


    .social-icons{
        clear: both;
        a{
            border: 1px solid @primary-color;
            background:@primary-color;
            color: @dark-text-color;
            display: inline-block;
            font-size: 14px;
            height: 40px;
            line-height: 40px;
            margin-right: 5px;
            position: relative;
            text-align: center;
            .transition(all,0.3s);
            width: 40px;
            .round(100%);

            &:last-child{
                margin:0;
            }

            &:hover{
                background: darken(@primary-color, 10%);
                color: @light-text-color;
            }
        }

    }        


}

.follow-us{
    color:@light-text-color;
    font-size:18px;
    letter-spacing:0.03em;
    text-transform:uppercase;
}

.footer-widget-area {
    
    padding: 80px 0 80px;

    .footer-widget {

        .footer-logo {
            margin-bottom: 15px;
        }               

    }

    .footer-widget-header h3{

        position: relative;
        font-size: 24px;
        margin-bottom: 6px;
        padding-bottom: 12px;
        font-family: @heading-font;
        letter-spacing: 0.03em;
        font-weight: 500;
        display: block;
        color: @heading-font-color;

        span{
            font-style: 400;
            color: @heading-font-color;
        }
    }


    .footer-useful-links{
        clear: both;

        li{
            margin-top: 8px;
        }
    }

    .footer-about{
        border-bottom:1px solid darken( @light-border-color, 40%);
        padding-bottom:40px;
        margin-bottom:40px;

        .footer-content{

            a{
                color:@primary-color; 
            }

        }
    }

    .opening-shedule{
        
        li{
            border-bottom:1px solid lighten(@light-border-color,5%);
            padding:12px 0px 12px;
            color:@default-font-color;

            &:first-child{
                padding-top:0px;
            }
            &:last-child{
                border:0 none;
            }

        }
    }    


    .fa-footer{
        display: inline;
        text-align: center;
        width: 12px;
        height: 12px;
        color: @primary-color;
        padding: 4px 6px 6px 0;
        margin-right: 0px;
    }

    .contact-us{

        clear:both;

        p{
            border-bottom: 1px solid lighten(@light-border-color,5%);
            padding-bottom: 10px;
            &:last-child{
                padding:0;
                border:0 none;

            }
        }
    }

    .fa-contact{
        display: inline-block;
        text-align: center;
        width: 12px;
        height: 12px;
        color: @primary-color;
        padding: 6px 6px 6px 0;
        margin-right: 6px;
        position:absolute;
    }


    .footer-widget + .footer-widget {
        margin-top: 24px;
    }

    li{
        line-height: 32px;
    }



}

.footer-widget-area-bg{
    clear: both;
}

.footer-contents{
    background:darken(@light-bg,1%);
    border-top: 1px solid lighten(@light-border-color,15%);
    padding: 32px 0 12px 0;

    .copyright-text{
        line-height:24px;
    } 

}

/***********************************************************************************************/
/* 13. SIDEBAR & WIDGETS */
/***********************************************************************************************/

.widget{
    background: @blog-bg-color;
    margin-bottom: 30px;
    padding: 20px;
}

.widget-title {

    position: relative;
    font-size: 24px;
    margin-bottom: 24px;
    text-transform: capitalize;
    font-family: @heading-font;
    letter-spacing: 0.03em;
    font-weight: 500;
    display: inline-block;
    color: @dark-text-color;

    span{
        font-style: 400;
        color: @heading-font-color;
    }

}

.widget ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.widget ul li {
    padding: 5px 0;
}
.widget ul li a {

    color: @default-font-color;

    &:hover{
        color: @primary-color;
    }
}

// Search Widget.

.search-form {

    margin: 0 0 12px 0;

    span.input-group-addon{

        .round(0px);
        background: @primary-btn-bg;
        border-right: 0px;
        color: @light-text-color;
        font-weight: bold;
        width: 10%;
        padding: 0 20px;

    }

    input[type="text"] {
        box-shadow: 0;

        margin-bottom:0;        
        font-size: 14px;
        padding-left: 5px;
        height: 42px;
        border: 0px;
        box-shadow: none;
        width: 100%;
        .transition(0.3s);
        &:focus{
            box-shadow: none;
        }

    }

}

// Category.

.widget-post-category {

    margin: 11px auto;

    li {
        margin: 5px 0;
        padding: 0;
        text-transform: capitalize;

        a {

            font-size: 15px;
            position: relative;                   
            .transition( 0.3s);

        }

    }

}

// Recent Posts.

.single-recent-post {

    margin-bottom: 26px;
    overflow: hidden;

    a {
        color: @default-font-color;
        margin-bottom: 8px;
        display: block;
        &:hover{
            color: @primary-color;
        }

    }

    span {

        i{
            font-size: 16px;
        }

        font-size: 14px;
        display: block;
        color: @primary-color;
        padding-left: 2px;
    }

}

.single-post-thumb {
    float: left;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-top: 5px;
    width: 100px;
}

// Tags.

.widget-recent-tags {

    margin: 0 auto;

    li {
        margin: 0px 5px 7px 0px;
        float: left;

        a{

            padding: 3px 7px;
            text-transform: capitalize;
            text-align: center;           
            border: 1px solid @primary-color;
            display: block;
            background: transparent;
            .transition(all, 0.3s);

            &:hover,
                &:active {                
                background: transparent;
                border: 1px solid @primary-color;
                background: @pure-white-bg;
            }

        }

    }

} 

/***********************************************************************************************/
/* Widget Link Color */
/***********************************************************************************************/

.sidebar-widget{

    a{

        color: @text-color;
        .transition(0.3s);
        &:hover{
            color: @primary-color;

        }

    }

}


/***********************************************************************************************/
/* CALENDAR WIDGET */
/***********************************************************************************************/

#calendar_wrap {

    margin: 0 auto 5px auto;

    #wp-calendar {

        width: 100%; 

        border: 1px solid @light-border-color;
        padding: 2px;

        caption {

            border-color: @light-border-color;
            border-image: none;
            border-style: solid solid none;
            border-width: 1px 1px medium;
            font-weight: bold;
            padding: 10px 0;
            text-align: center;
            text-transform: uppercase;
        }

        thead { 

            border-right: 1px solid @light-border-color;

        }

        thead th {
            border-right: 1px solid @light-border-color;
        }

        th {
            background: none repeat scroll 0 0 #F9F9F9;
            border-bottom: 1px solid @light-border-color;
            border-right: 1px solid @light-border-color;
        }
        tr th, thead th {
            font-weight: bold;
            padding: 9px 0;
            text-align: center;
        }

        tbody {
            color: #aaa; 

            tr td {
                border-bottom: 1px solid @light-border-color;
                border-right: 1px solid @light-border-color;
                padding: 6px;
                text-align: center;
                background: #f5f5f5; 
                .round(0px);
                text-align: center;                     
                .transition(0.3s);

                &:hover{
                    background: @light-bg;
                }
            }

            .pad {
                background: none; 
            }
        }

        tfoot{

            tr td {
                border-bottom: 1px solid @light-border-color;
                border-right: 1px solid @light-border-color;
                padding: 6px;
                text-align: center;
            }

            #next { 
                font-size: 12px; 
                text-transform: uppercase; 
                text-align: center;
            }
            #prev { 
                text-align: center;
                font-size: 12px; 
                text-transform: uppercase; 
                padding-top: 10px; 
            }
        }

        #today {
            background-color: @primary-color;
            color: @light-text-color;
            font-weight: bold;
        }

    }

}

/***********************************************************************************************/
/* 14. RESPONSIVE STYLING */
/***********************************************************************************************/

/* Tablet Portrait size to standard (devices and browsers) */

@media only screen and (max-width: 1224px) {

    footer {
        .footer-logo{

            &:after{
                display:none;
            }

        }
    }

}

@media only screen and (min-width:991px) and (max-width: 1200px)  {

    .header-top {

        .icon-box {
            .icon-container {
                font-size: 25px;
            }
            .text {
                font-size: 13px;
                .head-heading{
                    font-size: 13px;
                }
            }
        }
        .btn-quote {
            .btn-request_quote{
                font-size: 12px;
                padding: 12px 3px;
            }
        }

    }

    // TOP TEXT

    .main-top-header{
        p{
            font-size:13px;
        }
    }

    #slider_1{

        .slider-content {

            h2 {
                font-size: 100px;
                line-height: 120px;
                font-weight: 700;
            }

            h3 {

                font-size: 32px;
            }

            p{
                font-size: 20px;
                line-height: 25px;   
            }


        }

    }

}

@media only screen and (min-width: 992px) and (max-width: 1370px) {

    // SLIDER 1

    #slider_1{

        text-align: center;

        .slider_item_container{
            min-height: 520px;
        }

        .item img {
            display: block;
            width: 100%;
            height: auto;
        }

        .slider-content {

            margin-top: 24px;

            h3 {
                font-size: 24px;
                line-height: 32px;
            }

            h2 {
                font-size: 60px;
                line-height: 60px;
            }


        } 

    } 

    /*----- Banner ----*/

    .section-banner{ 
        
        padding:150px 0px;  

        h2 {
            font-size: 60px;
            line-height: 60px;
        }
    } 

    .btn-theme{
        line-height: 24px;
    }

}

/* Tablet Portrait size to standard 960 (devices and browsers) */

@media only screen and (max-width: 991px) {
    
    /*-----  HELPER CLASS----*/
    
    .no-padding-pricing-table,
    .no-padding{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .header-top {
        .col-logo {
            text-align: center;
        }
    }

    /*-----  TOP BAR ----*/
    
    .main-header{

        .top-bar{
            display:none;
        }

        ul.top-bar-info{
            
            padding: 0 0 12px 0;
            margin: 0;
            text-align: center;

            li{
                margin-right: 0;
                display: inline-block;
                width: 49%;

                &:after{
                    display: none;
                }

            }

        }
    }

    .top-donation-btn-container{
        text-align: center;
        margin-top: 12px;
        margin-bottom: 24px;
    }

    a.logo{
        text-align: center;
    }

    .navbar-default {

        .navbar-nav li ul.drop-down{
            top: 68px;
        }

        .navbar-header{
            width: 100%;
            text-align: center;
            clear: both;
            float: none ;
            margin: 0 ;
        }

        .navbar-toggle{
            span.icon-bar{
                height: 1px;
            }
        }

        .navbar-right {
            float: none;
            padding:0;
            margin: 0px 48px 0 0;
        }

        .navbar .navbar-collapse {
            text-align: center;
        }

    }

    .top-location-info{
        display: block;
        text-align: center;
    }

    .main-menu-container{

        float: none;

    }


    .main-header {
        background: @dark-bg;
        position: relative;
    }


    .sticky-wrapper{
        background: rgba(0,0,0,0.8);
    }


    .navbar-nav > li {
        margin-left: 0;
    }
    header.one-page .navbar-nav > li > a {
        padding: 15px 5px;
        font-size: 15px;
        padding-bottom: 22px;
    }



    // SLIDER 1

    #slider_1{

        text-align: center;

        .slider_item_container{
            min-height: 350px;
        }

        .item img {
            display: block;
            width: 100%;
            height: auto;
        }


        .slider-content {
            top: 50%;
            transform: translateY(-48%);


            i.icon{
                &:after,
                    &:before{
                    width:100px;
                    line-height:100px;
                    height:100px;
                    font-size:60px;
                    margin-bottom:20px;
                }

            }            

            h2 {

                font-size: 42px;
                line-height: 48px;
                padding-bottom:0px;
                margin-bottom:16px;

                &:after{
                    display:none;
                }

            }

            h3 {
                display:none;
            }

            a.banner-video-icon {
                border: 2px solid #f2f2f2;
                color: @primary-color;
                width: 60px;     
                height: 60px;
                font-size: 24px;
                line-height: 55px;
                margin: 0 auto 12px;

                >i{
                    margin-left:6px; 
                }

                &:hover{
                    border: 2px solid @primary-color;

                }
            }


        } 

    }


    //  PAGE HEADER


    .page-header{

        padding: 80px 0;

    }


    .main-sidebar{
        width: 100%;
        border: 0px solid transparent;
    }

    .logo{

        text-align: center;
    }


    /*----- PROCESS BLOCK  ----*/
    
    .process-step-1,
    .process-step-2{
        margin-bottom: 24px
    }
    
    /*----- COUNTER BLOCK ----*/
    
    .counter-block-1,
    .counter-block-2{
        margin-bottom: 24px;
    }
    
    /*----- PRICING TABLE ----*/
    .price-table-layout-1{ 
        margin-bottom: 24px; 
    }
    
    /*----- TEAM LAYOUT  ----*/
    
    .team-layout-1{
        margin-bottom: 24px;
    }

    

    // HOME-2 Gallery.

    .gallery-carousel{
        .owl-controls{
            display:none;  
        }
    }
    
    /*----- COUNTDOWN LAYOUT ----*/
    
    #coming_countdown{

        font-size: 24px;

        .countdown_container{
            span.count_number{
                font-size: 72px;
                line-height: 60px;
            }
            span.count_text{
                font-size: 24px;
                line-height: 42px;
            }

        }

    }        

    .footer-widget-area {

        padding: 42px 0 0 0;

    }

    .footer-widget{
        margin-bottom: 36px;
    }

    .footer-intro{
        display: none;
    }

    .copyright-text{
        text-align: center;
    }

}

/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {

    /*-----  HELPER CLASS----*/
    
    .no-padding-pricing-table,
    .no-padding{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    
    .logo-layout-2{
    
        [class^="col-"] {
            padding-left: 15px !important;
            padding-right: 15px !important;
            margin-left: -1px;
            margin-top: -1px;
        }
    }
    
    /*-----  TOP BAR ----*/
    
    .main-header{

        .top-bar{
            display:none;
        }

        ul.top-bar-info{
            
            padding: 0 0 12px 0;
            margin: 0;
            text-align: center;

            li{
                margin-right: 0;
                float: left;
                width: 50%;

                &:after{
                    display: none;
                }

            }

        }
    }

    .main-menu-container{

        float: none;

    }

    .navbar-default {

        .navbar-toggle {
            margin-top: 22px;
            float: right;
            .round(0px);
            span.icon-bar{
                color: @primary-color;
            }
        }

        .nav > li > a,
        header.one-page .navbar-nav > li > a {
            padding: 5px 15px;
        }

        .navbar-nav > li > a.link-active {
            background: none;
        }

        .navbar .navbar-right {
            width: 100%;
            text-align: left;
        }

        .navbar-collapse{
            background: #0d0d0d;
            padding: 15px 0;
            border: 0;
        }

        .pull-left{
            float: none !important;
            overflow: hidden;
        }

        .pull-right{
            float: none !important;
        }
        
        .navbar-nav li ul.drop-down,
        .navbar-nav li ul.drop-down li ul.drop-down.level3 {
            background: transparent;
            border: none;
            -webkit-transform: rotateX(0deg);
            -moz-transform: rotateX(0deg);
            -ms-transform: rotateX(0deg);
            -o-transform: rotateX(0deg);
            transform: rotateX(0deg);
            -webkit-transform: rotateX(0deg);
            -moz-transform: rotateX(0deg);
            -ms-transform: rotateX(0deg);
            -o-transform: rotateX(0deg);
            transform: rotateX(0deg);
            position: relative;
            width: auto;
            visibility: visible;
            opacity: 1;
            top: inherit;
            left: inherit;
        }

        .navbar-nav li ul.drop-down li a,
        .navbar-nav li ul.drop-down li ul.drop-down.level3 li a {
            background: none;
            color: @light-text-color;
            font-size: 14px;
            text-transform: uppercase;
            padding: 4px 20px;
        }

        .navbar-nav li ul.drop-down li a:hover,
        .navbar-nav li ul.drop-down li ul.drop-down.level3 li a:hover {
            color: @light-text-color;
        }
        
        
        .navbar-nav li ul.drop-down li.drop a{
            color: @light-text-color;
        }

        .navbar-nav{

            margin:0px;
            width: 100%;
            text-align: left;
            background: rgba(0,0,0,0.7);

            li{
                a{
                    font-weight: bold;
                    color: @primary-color;
                }
            }

            .drop-down{

                li{
                    a{
                        color: @light-text-color;
                    }
                }


            }
        }

    }

    .logo{
        text-align: center;
    }

    .main-header {
        background: @dark-bg;
        position: relative;
    }

    .sticky-wrapper{
        background: rgba(0,0,0,0.8);
    }


    .stuck{
        position: initial;
    }  

    .section-content-block{
        padding: 60px 0;
    }

    .section-heading-wrapper,
    .section-heading-wrapper-alt{
        margin-bottom: 0px;
        
        h2{
            font-size: 32px;
            font-weight: 400;
        }
        
    }


    // PAGE HEADER

    .page-header{

        padding: 50px 0;

        h3{
            text-align: center;
        }

        .page-breadcrumb{
            text-align: center;
        }

    }     

    // HOME#1 SLIDER

    #slider_1{

        .slider_item_container{
            min-height: 350px;
        }

        .slider-content {

            text-align: center;
            top: 50%;
            transform: translateY(-55%);

            i.icon{
                &:after,
                    &:before{
                    width:100px;
                    line-height:100px;
                    height:100px;
                    font-size:60px;
                    margin-bottom:20px;
                }

            }

            h2 {

                font-size: 32px;
                line-height: 42px;
                padding-bottom:0px;
                margin-bottom:16px;

                &:after{
                    display:none;
                }

            }

            h3 {
                display:none;
            }

            a.banner-video-icon {
                border: 2px solid #f2f2f2;
                color: @primary-color;
                width: 60px;     
                height: 60px;
                font-size: 24px;
                line-height: 55px;
                margin: 0 auto 12px;

                >i{
                    margin-left:6px; 
                }

                &:hover{
                    border: 2px solid @primary-color;

                }
            }

            .slider-button{
                margin-top: 16px;  

                a.btn-slider {
                    font-size: 14px;
                    display:inline-block;
                    height: 42px;
                    line-height: 24px;
                    padding-left: 24px;
                    padding-right: 24px;
                }

            }



        }

    }

    // Banner

    .section-banner{    
        padding:130px 0px;  
    } 
    
    .section-banner{

        .banner-content{
            transform: translateY(-30px);  
            h2 {
                font-size: 36px;
                line-height: 42px;
            }

            h3 {
                display:none;
            }        

        } 

    }

    /*----- PROCESS BLOCK  ----*/
    
    .process-step-1,
    .process-step-2{
        margin-bottom: 24px
    }
    
    /*----- COUNTER BLOCK ----*/
    
    .counter-block-1,
    .counter-block-2{
        margin-bottom: 24px;
    }
    
    /*----- APPOINTMENT FORM. ----*/
    
    .appointment-form-wrapper{
        
        clear: both;

        .appointment-form-heading{
            position:relative; 
            background:@primary-color;
            margin: -2px -6px 48px -5px;
            text-align: center;

            h3{
                margin: 0;
                display: block;

                &:after{
                    display: none;
                }
            }

            span{
                float: none;
                display: block;
                margin-top: 12px;
            }
        }

        .appoinment-form{

            padding: 0 6px;

        }


    }
    
    /*----- PRICING TABLE ----*/
    
    .price-table-layout-1{ 
        margin-bottom: 24px; 
    }
    
    /*----- TEAM LAYOUT  ----*/
    
    .team-layout-1{
        margin-bottom: 24px;
    }


    // CTA

    .section-cta{
        .cta-content{
            h2{
                font-size:28px;
                line-height:32px;
            }
            h4{
                font-size:18px;
                span{
                    font-size:22px;
                }
            }
        }
    }

    .cta-layout-01{
        
        h4{
            font-size: 42px;
            line-height: 48px;
        }
        
    }
    
    // Appointment Form PAGE.

    .appointment-form-wrapper{
        margin: 48px 0;
        padding: 0px 5px 28px;

        h3{
            font-weight:600;
            margin:0 -5px 32px;
            position:relative;
        }  
    }
    .appoinment-form {
        padding: 0 0px;
    }
    
    /*----- COUNTDOWN LAYOUT  ----*/
    
    #coming_countdown{

        font-size: 24px;

        .countdown_container{
            span.count_number{
                font-size: 50px;
                line-height: 40px;
            }
            span.count_text{
                font-size: 18px;
                line-height: 42px;
            }

        }

    }


    // Blog

    .section-home-blog{
        .latest-news-container{
            margin-bottom:30px;
        }
    }

    /*----- FAQ LAYOUT ----*/
    
    .faq-layout .faq-box .panel-heading [data-toggle="collapse"]::after{
        display:none;  
    }


    // 404 PAGE.

    .message-container-404{

        margin: 48px 0;

        .text-404{
            margin:80px 0;
        }

        .search-form-404 {
            padding: 5px;    
            width: 100%;
        }

        .message-text-404{
            padding: 24px 0px;
        }

    }

    .article-container-fix,
    .main-sidebar {
        width: 100%;
        margin-left: 0px;
        border: 0px solid transparent;
    }

    .post-details-container{
        h3 {

            margin-top: 12px;

        }
    }

    .post-box-container {

        .bd-post-image-container{

            margin: 0 auto;

        }

    }

    .image_gallery_carousel{

        margin-bottom: 48px;
    }

    // Campaigns.

    a.btn-load-more{
        margin-bottom: -24px;
        margin-top: -16px;
    }

    .copyright-text{
        text-align: center;
    }

    //event home 2   


    .footer-widget-area {

        padding: 42px 0 0 0;

    }

    .footer-widget{
        margin-bottom: 36px;
    }

    .footer-intro{
        display: none;
    }

    .copyright-text{
        text-align: center;
    }


}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */

@media only screen and (min-width: 0px) and (max-width: 479px) {
    
    
    /*-----  HELPER CLASS----*/
    .no-padding-pricing-table,
    .no-padding{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    
    .logo-layout-2{
    
        [class^="col-"] {
            padding-left: 15px !important;
            padding-right: 15px !important;
            margin-left: -1px;
            margin-top: -1px;
        }
    }

    .main-header {
        background: @dark-bg;
        position: relative;
    }


    .sticky-wrapper{
        background: rgba(0,0,0,0.8);
    }
    
    /*-----  TOP BAR ----*/
    
    .main-header{

        .top-bar{
            display: none;
            text-align: center;
        }

        ul.top-bar-info{

            padding: 0 0 12px 0;
            margin: 0;
            text-align: left;

            li{
                margin-right: 0;
                display: block;
                width: 100%;

                &:after{
                    display: none;
                }

            }

        }
    }

    .main-menu-container{

        float: none;

    }

    a.logo{
        text-align: center;
        width: 75%;
    }

    .navbar-default {

        .navbar-toggle{

            span.icon-bar{
                height: 1px;
            }
        }

        .navbar-nav{
            margin:0px;
            width: 100%;
            text-align: left;
            background: rgba(0,0,0,0.7);

            li{
                margin-left: 0px;

                a{
                    font-weight: bold;
                    color: @primary-color;
                }
            }

            .drop-down{
                    color: @light-text-color;
                li{
                    a{
                        color: @light-text-color;
                    }
                }


            }
        }

    }

    .logo{
        text-align: center;
    }

    .section-heading-wrapper,
    .section-heading-wrapper-alt{
        margin-bottom: 0px;
        
        h2{
            font-size: 32px;
            font-weight: 400;
        }
        
    }

    .section-content-block{
        padding: 32px 0;
    }

    .section-heading{
        font-size: 32px;
        line-height: 36px;
        font-weight: normal;
        margin-bottom: 24px;

        &:after,
            &:before{
            display: none;
        }
    }

    .section-subheading{
        display: none;
    }

    // Page Header.

    .page-header{
        padding: 80px 0;        
        h3{
            font-size: 24px;
            line-height: 32px;
            margin: 12px 0;
        }
    }    

    /*----- HOME SLIDER ----*/

    #slider_1{

        .slider_item_container{
            min-height: 300px;
        }

        .slider-content {
            top: 50%;
            transform: translateY(-55%);            


            i.icon{
                &:after,
                    &:before{
                    width:50px;
                    line-height:50px;
                    height:50px;
                    font-size:20px;
                    margin:40px 0 10px;
                }

            }


            h2 {

                font-size: 32px;
                line-height: 42px;
                padding-bottom:0px;
                margin-bottom:16px;

                &:after{
                    display:none;
                }

            }

            h3 {
                display:none;
            }

            a.banner-video-icon {
                border: 2px solid #f2f2f2;
                color: @primary-color;
                width: 60px;     
                height: 60px;
                font-size: 24px;
                line-height: 55px;
                margin: 70px auto 10px;

                >i{
                    margin-left:6px; 
                }

                &:hover{
                    border: 2px solid @primary-color;

                }
            }  


            .slider-button{
                margin-top: 10px;  

                a.btn-slider {
                    font-size: 14px;
                    display:inline-block;
                    height: 42px;
                    line-height: 24px;
                    padding-left: 24px;
                    padding-right: 24px;
                }

            }

        }


    }
    
    
    /*----- HOME BANNER ----*/
    
    

    // Banner

    .section-banner{    
        padding:80px 0px;  
    } 

    .section-banner{

        .banner-content{
            transform: translateY(-20px);  

            h2{
                font-size: 32px;
            }

            h3{
                display: none;
            }
            
            a.btn-slider{
                font-size: 14px;
                display:inline-block;
                height: 42px;
                line-height: 24px;
                padding-left: 24px;
                padding-right: 24px;
            }


        } 

    }

    /*----- PROCESS BLOCK  ----*/
    
    .process-step-1,
    .process-step-2{
        margin-bottom: 24px;
    }

    /*----- COUNTER BLOCK ----*/
    
    .counter-block-1,
    .counter-block-2{
        margin-bottom: 24px;
    }
    
    /*----- CTA ----*/

    .cta-layout-01,
    .cta-layout-02,
    .cta-video-box{
        h4{
            font-size: 24px;
            line-height: 32px;
        }
    }
    

    /*----- APPOINTMENT FORM ----*/
    
    .appointment-form-wrapper{
        padding: 0;
        padding-bottom: 24px;
        clear: both;

        .appointment-form-heading{
            position:relative; 
            background:@primary-color;
            padding: 12px 24px 18px;
            margin: -1px -1px 48px -1px;
            text-align: center;

            h3{
                margin: 0;
                display: block;

                &:after{
                    display: none;
                }
            }

            span{
                float: none;
                display: block;
                margin-top: 12px;
            }
        }

        .appoinment-form{

            padding: 0 6px;

        }


    }

    /*----- TEAM LAYOUT  ----*/
    
    .team-layout-1{
        margin-bottom: 24px;
    }
    
    /*----- NEWSLETTER LAYOUT  ----*/

    .horizontal-newsletter{
        .news-subscription {
            button {
                font-size: 16px;
                width: 100px;
            }
        }
    }

    /*----- BLOG LAYOUT  ----*/

    .latest-news-container{

        border: 0px solid @light-border-color;

        .news-content{

            padding: 12px 16px 24px;

        }

    }
   
    /*----- FAQ LAYOUT ----*/
    
    .faq-layout .faq-box .panel-heading [data-toggle="collapse"]::after{
        display:none;  
    }
    
    /*----- COUNTDOWN LAYOUT ----*/

    #coming_countdown{

        font-size: 24px;

        .countdown_container{
            span.count_number{
                font-size: 50px;
                line-height: 40px;
            }
            span.count_text{
                font-size: 14px;
                line-height: 42px;
            }

        }

    }

    /*----- LOGO LAYOUT ----*/

    .logo-layout-1,
    .logo-layout-2{

        .logo{

            margin-top: 0px;

        }

    }


    // 404 PAGE.

    .message-container-404{

        margin: 48px 0;

        .text-404{
            margin:80px 0;
        }

        .search-form-404 {
            padding: 5px;    
            width: 100%;
        }

        .message-text-404{
            padding: 24px 0px;
        }

    }

    // Footer.

    .about-footer{
        margin-bottom: 32px;
        padding-bottom: 22px;
    }

    .footer-widget-area {

        padding: 42px 0 0 0;

        .textwidget {

            i.fa{

            }

            p {


            }
        }

    }

    .footer-widget{
        margin-bottom: 36px;

        .footer-useful-links{
            clear: both;

            li{
                width: 100%;
                float: none;
            }
        }
    }

    .footer-intro{
        display: none;
    }

    .copyright-text{
        text-align: center;
    }

}

/* Tab Portrait Size to Tab Landscape Size (devices and browsers) */
@media only screen and (min-width:380px) and (max-width: 480px)  {
    .xs-margin{
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media only screen and (min-width:480px) and (max-width: 600px)  {
    .xs-margin{
        margin-left: 70px;
        margin-right: 70px;
    }
}

@media only screen and (min-width:600px) and (max-width: 767px)  {
    .xs-margin{
        margin-left: 120px;
        margin-right: 120px;
    }
}